






















import hasPricespiderBtn from 'library/mixins/has-pricespider-btn'
import hasVideoModalBtn from 'library/mixins/has-video-modal-btn'

export default
	inject: themeOverride: default: 'default'

	mixins: [ hasPricespiderBtn, hasVideoModalBtn ]

	props: block: Object

