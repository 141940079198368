



















import WysiwygButtons from './wysiwyg-buttons'

export default

	props: block: Object

	components: {
		WysiwygButtons
	}

